<template>
    <div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="关键字:">
					<el-input
						clearable
						@clear='clearContent'
						v-model="filters.searchKey"
						placeholder="请输入关键字"
						@input="(e) => (filters.searchKey = utils.validForbid(e))"
					>
					</el-input>
				</el-form-item>
				<el-form-item label="启用标识:">
					<el-select v-model="filters.selectc" clearable>
						<el-option
							v-for="item in optionsData"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="users"
			highlight-current-row
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%"
		>
			<el-table-column type="index" width="80" align="center" :index='indexMethod'> </el-table-column>
			<el-table-column prop="versionInfo" label="版本号" width="150" align="center"></el-table-column>
			<el-table-column prop="appType" label="客户端" width="100" align="center">
				<template slot-scope="scope">
					{{ scope.row.appType == 0 ? "上传客户端" : "图审客户端" }}
				</template>
			</el-table-column>
			<el-table-column prop="updateContent" label="更新内容" width="130" align="center"></el-table-column>
			<el-table-column prop="isEnable" label="启用标识" width="100" align="center">
				<template slot-scope="scope">
					<el-tag
						:type="scope.row.isEnable == true ? 'success' : 'danger'"
						disable-transitions
					>{{ scope.row.isEnable == true ? "启用" : "禁用" }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="appType" label="下载" width="100" align="center">
				<template slot-scope="scope">
					<a :href="scope.row.downloadUrl" target="_blank">下载</a>
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pages.pageIndex"
			:page-sizes="pages.pageArr"
			:page-size="pages.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="pages.dataCount"
			>
			</el-pagination>
		</el-col>

		<!--新增-->
		<el-dialog
			title="新增"
			:visible.sync="addFormVisible"
			v-model="addFormVisible"
			:close-on-click-modal="false"
		>
			<el-form
				:model="addForm"
				label-width="130px"
				:rules="addFormRules"
				ref="addForm"
			>
				<el-form-item label="版本号:" prop="versionInfo">
					<el-input v-model="addForm.versionInfo" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="客户端:" prop="appType">
					<el-select
						v-model="addForm.appType"
						placeholder="请先选择客户端"
					>
						<el-option
							v-for="item in appTypes"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否启用:" prop="IsEnable">
						<el-select v-model="addForm.isEnable" placeholder="请选择是否启用">
							<el-option
								v-for="item in optionsData"
								:key="item.value"
								:label="item.name"
								:value="item.value"
							>
							</el-option>
						</el-select>
				</el-form-item>
				<el-form-item label="更新内容:" prop="updateContent">
					<el-input type="textarea" v-model="addForm.updateContent"></el-input>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" v-model="addForm.remarks"></el-input>
				</el-form-item>
				<el-form-item label="上传文件:" v-model="addForm.file">
					<el-upload
						action="#"
						class="upload-demo"
						drag
						:show-file-list="true"
						:auto-upload="false"
						:on-change="onChange"
					>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click.native="addFormVisible = false">取消</el-button>
			<el-button
				type="primary"
				@click.native="addSubmit"
				:loading="addLoading"
				>提交</el-button
			>
			</div>
		</el-dialog>
		<!--编辑-->
		<el-dialog
			title="编辑"
			:visible.sync="editFormVisible"
			v-model="editFormVisible"
			:close-on-click-modal="false"
		>
			<el-form
			:model="editForm"
			label-width="130px"
			:rules="editFormRules"
			ref="editForm"
			>
				<el-form-item label="版本号:" prop="versionInfo">
					<el-input v-model="editForm.versionInfo" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="客户端:" prop="appType">
					<el-select
						v-model="editForm.appType"
						placeholder="请先选择客户端"
					>
						<el-option
							v-for="item in appTypes"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否启用:" prop="IsEnable">
						<el-select v-model="editForm.isEnable" placeholder="请选择是否启用">
							<el-option
								v-for="item in optionsData"
								:key="item.value"
								:label="item.name"
								:value="item.value"
							>
							</el-option>
						</el-select>
				</el-form-item>
				<el-form-item label="更新内容:" prop="updateContent">
					<el-input type="textarea" v-model="editForm.updateContent"></el-input>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" v-model="editForm.remarks"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click.native="editFormVisible = false">取消</el-button>
			<el-button
				type="primary"
				@click.native="editSubmit"
				:loading="editLoading"
				>提交</el-button
			>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import {
	getAppVersionList,
	updateAppVersion,
	addAppVersion,
	deleteAppVersion,
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
export default {
	components: { Toolbar },
	data() {
		return {
			utils: '',
			users: [],
			roles: [],
			listLoading: false,
			currentRow: null,
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
			//顶部筛选条件
			filters: {
				searchKey: "",
				selectc: true,
			},
			//关于分页的obj
			pages: {
				pageSize: 20, //默认每页条数
				pageArr: [10, 20, 30, 40], //默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			//新增界面是否显示
			addFormVisible: false,
			addLoading: false,
			addFormRules: {
				versionInfo: [
					{ required: true, message: "请输入版本号", trigger: "blur" },
				],
				appType: [{ required: true, message: "请选择客户端", trigger: "blur" }],
				updateContent: [{ required: true, message: "请输入更新内容", trigger: "blur" }],
			},
			//dialog数据
			addForm: {
				versionInfo: "",
				appType: "",
				isEnable: true,
				remarks: '',
				updateContent: '',
			},
			//编辑界面是否显示
			editFormVisible: false,
			editLoading: false,
			editFormRules: {
				versionInfo: [
					{ required: true, message: "请输入版本号", trigger: "blur" },
				],
				appType: [{ required: true, message: "请选择客户端", trigger: "blur" }],
				updateContent: [{ required: true, message: "请输入更新内容", trigger: "blur" }],
			},
			//dialog数据
			editForm: {
				versionInfo: "",
				appType: "",
				isEnable: true,
				remarks: '',
				updateContent: '',
			},
			//是否启用状态
			optionsData: [{
				value: true,
				name: "启用",
			},{
				value: false,
				name: "禁用",
			}],
			appTypes: [
                {name: '上传客户端',value: 0},
                {name: '图审客户端',value: 1},
            ],
			file: null,
		};
	},
	methods: {
		// 清空筛选框内容，重新获取数据
		clearContent(){
			this.getDataList()
		},
		//当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
		//显示新增界面
		addAppVersion() {
			this.addForm = {
				versionInfo: "",
				appType: "",
				isEnable: true,
				remarks: '',
				updateContent: '',
			};
			this.addFormVisible = true;
		},
		//显示编辑界面
		editAppVersion() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要编辑的一行数据！",
					type: "error",
				});
				return;
			}
			this.editForm = Object.assign({}, row);
			this.editFormVisible = true;
		},
		//新增
		addSubmit: function () {
			this.$refs.addForm.validate((valid) => {
				if (valid) {
					if(!this.file) {
						this.$message({
							message: '请上传文件',
							type: "error",
						});
						return
					}
					this.$confirm("确认提交吗？", "提示", {}).then(() => {
						this.addLoading = true;
						let fd = new FormData();
						fd.append('file', this.file.raw)
						fd.append('versionInfo', this.addForm.versionInfo)
						fd.append('appType', this.addForm.appType)
						fd.append('isEnable', this.addForm.isEnable)
						fd.append('remarks', this.addForm.remarks)
						fd.append('updateContent', this.addForm.updateContent)

						addAppVersion(fd).then((res) => {
							if (util.isEmt.format(res)) {
								this.addLoading = false;
								return;
							}

							if (res.data && res.data.success) {
								this.addLoading = false;
								this.$message({
									message: res.data.message,
									type: "success",
								});
								this.addFormVisible = false;
								this.$refs["addForm"].resetFields();
								this.getDataList();
							} else {
								this.addLoading = false;
								this.$message({
									message: res.data.message,
									type: "error",
								});
							}
						});
					});
				}
			});
		},
		//编辑
		editSubmit: function () {
			this.$refs.editForm.validate((valid) => {
				if (valid) {
					this.$confirm("确认提交吗？", "提示", {}).then(() => {
						this.editLoading = true;
						let fd = new FormData();
						fd.append('versionInfo', this.editForm.versionInfo)
						fd.append('appType', this.editForm.appType)
						fd.append('isEnable', this.editForm.isEnable)
						fd.append('remarks', this.editForm.remarks)
						fd.append('updateContent', this.editForm.updateContent)
						fd.append('id', this.editForm.id)

						updateAppVersion(fd).then((res) => {
							if (util.isEmt.format(res)) {
								this.editLoading = false;
								return;
							}
							if (res.data.success) {
								this.editLoading = false;
								this.$message({
									message: res.data.message,
									type: "success",
								});
								this.editFormVisible = false;
								this.getDataList();
							} else {
								this.editLoading = false;
								this.$message({
									message: res.data.message,
									type: "error",
								});
							}
						});
					});
				}
			});
		},
		//删除
		deleteAppVersion() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要删除的一行数据！",
					type: "error",
				});
				return;
			}
			this.$confirm("确认删除该版本吗?", "提示", {
				type: "warning",
			})
			.then(() => {
				this.listLoading = true;
				let params = { id: row.id };
				deleteAppVersion(params).then((res) => {
					if (util.isEmt.format(res)) {
						this.listLoading = false;
						return;
					}
					if (res.data.success) {
						this.listLoading = false;
						this.$message({
							message: res.data.message,
							type: "success",
						});
						this.userFormVisible = false;
						this.getDataList();
					} else {
						this.listLoading = false;
						this.$message({
							message: res.data.message,
							type: "error",
						});
					}
				});
			})
			.catch(() => {});
		},
		// 查询
		getUsers(){
			this.pages.pageIndex = 1
			this.getDataList()
		},
		//获取列表
		getDataList() {
			this.listLoading = true;
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				searchKey: this.filters.searchKey,
				isEnable: this.filters.selectc,
			};  
			getAppVersionList(params).then((res) => {
				this.users = res.data.response.data;
				this.listLoading = false;

				this.pages.dataCount = res.data.response.dataCount;
				this.pages.pageIndex = res.data.response.pageIndex;
				if (res.data.response.pageSize > 0) {
					this.pages.pageSize = res.data.response.pageSize;
				}
			});
		},

		callFunction(item) {
			this[item.func].apply(this, item);
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getDataList();
		},
		handleSizeChange(val) {
			this.pages.pageSize = val;     
			this.getDataList();
		},
		validForbid(value, number = 2550) {
			value = value
			.replace(
				/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
				""
			)
			.replace(/\s/g, "");
			if (value.length >= number) {
				this.$message({
					type: "warning",
					message: `输入内容不能超过${number}个字符`,
				});
			}
			return value;
		},
		// 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
		onChange(file, fileList) {
			this.file = file
		}
	},
	mounted() {
		this.utils = util
		this.getDataList();
		let routers = window.localStorage.router
		? JSON.parse(window.localStorage.router)
		: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	},
};
</script>
<style lang="stylus" scoped>
.rolesTop {
	width: 100%;
	display: flex;
}

.rolesInp {
	width: 300px;
	margin-right: 20px;
}

.pageBar {
  	margin-top: 15px;
}
</style>
